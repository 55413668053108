import React, { useContext } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import HomeIntro from "../../components/organism/HomePageComponent/HomeIntro"
import PageSection from '../../components/organism/HomePageComponent';
import Carousel from '../../components/organism/carousel'
import {Card} from '../../components/molecule/Card'
import { SiteContext } from '../../Context/site'
import { PagePropsType, lang, ContentShape } from '../../entities/countryIndexQueries'
import { CurrentView } from '../../entities/templates';
import translations from '../../entities/translations';
import PortableText from '../../components/sanityBlockContents/PortableText';
import Text from "../../components/atoms/Text";
import { imageURLBuilder, getCardSize, getCountryPageTranslation as gCPT } from '../../utils';
import useSetGlobalState from '../../components/hooks/useSetGlobalState'

const IndexPage: React.FC<PagePropsType> = (props) => 
{
  const pageID = translations.globals.subMenuTranslation;
  const buttonTransaltion = translations.globals.buttons;
  const { state } = useContext(SiteContext);
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.index })

  return (
    <Layout seoTitle={page?.metadata?.page_title[state?.lang as lang] as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <HomeIntro
      readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView.overview, lang: state?.lang as lang })}`} 
      sanityContext={page?.countryIntroduction && page?.countryIntroduction[state?.lang as lang] as ContentShape[lang]}
        id={pageID.Überblick[state?.lang as lang]!} />

      <PageSection
      readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView.highlights, lang: state?.lang as lang })}`}  
      sanityContext={page?.highlightsIntroduction && page?.highlightsIntroduction[state?.lang as lang]}
      id={pageID.Höhepunkte[state?.lang as lang]!} layerBackground="bg-white-smoke"
      title={state?.countryName!} >
        <Carousel arrayLength={page.countryHighlights?.length || 0}>
         {page.countryHighlights?.map((item, i: number, arr) => <Card
          key={i}
          _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
          imageAlt={item.hightlightImage.alt[state?.lang as lang]}
          imageUrl={imageURLBuilder(item.hightlightImage?.image).url()}
          title={item.title[state?.lang as lang] || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          cardSize={getCardSize(arr)}
          /> )}
        </Carousel>
      </PageSection>

      <PageSection 
        readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView.regions, lang: state?.lang as lang })}`} 
        sanityContext={page.regionIntroduction && page.regionIntroduction[state?.lang as lang]} 
        id={pageID.Orte[state?.lang as lang]!} title={state?.countryName!} >
        <Carousel arrayLength={page?.region?.length || 0}>
          {page.region?.map((item, i: number, arr) => <Card
            key={i}
            _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
            imageAlt={'alt'}
            imageUrl={imageURLBuilder(item.regionImage?.image).url()}
            title={item.title[state?.lang as lang] || ''}
            ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
            cardType="COUNTRY"
            bodyText={''}
            cardSize={getCardSize(arr)}
          /> )}
        </Carousel>
      </PageSection>

      <PageSection 
        readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView.trips, lang: state?.lang as lang })}`} 
        sanityContext={page.tripIntroduction && page.tripIntroduction[state?.lang as lang]}
        id={pageID.Reisen[state?.lang as lang]!} title={state?.countryName!}  layerBackground="bg-white-smoke">
         <Carousel arrayLength={page.trips?.length || 0}>
         {page.trips?.map((item, i: number, arr) => <Card
          key={i}
          _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
          imageAlt={(item.tripImage && item.tripImage.alt[state?.lang as lang]) || ''}
          imageUrl={imageURLBuilder(item.tripImage?.image).url()}
          title={item.title[state?.lang as lang] || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          cardSize={getCardSize(arr)}
          /> )}
        </Carousel>
      </PageSection>

      <PageSection 
        readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView["trips-previous"], lang: state?.lang as lang })}`} 
        sanityContext={page.previousTripIntroduction && page.previousTripIntroduction[state?.lang as lang]}
        id={pageID.Inspirationen[state?.lang as lang]!} title={state?.countryName!} >
          <Carousel arrayLength={page.previousTrips?.length || 0}>
            {page.previousTrips?.map((item, i: number, arr) => <Card
              key={i}
              _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
              imageAlt={(item.tripImage && item.tripImage.alt[state?.lang as lang]) || ''}
              imageUrl={imageURLBuilder(item.tripImage?.image).url()}
              title={item.title[state?.lang as lang] || ''}
              ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
              cardType="COUNTRY"
              cardSize={getCardSize(arr)}
            /> )}
        </Carousel>
      </PageSection>
      
      <PageSection 
        readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView.accommodation, lang: state?.lang as lang })}`} 
        sanityContext={page.accomodationIntroduction && page.accomodationIntroduction[state?.lang as lang]}
        id={pageID.Unterkünfte[state?.lang as lang]!} title={state?.countryName!} layerBackground="bg-white-smoke" >
          <Carousel arrayLength={page.accommodation?.length || 0}>
            {page.accommodation?.map((item, i: number, arr) => <Card
              key={i}
              _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
              imageAlt={(item.accommodationImage && item.accommodationImage.alt[state?.lang as lang]) || ''}
              imageUrl={imageURLBuilder(item.accommodationImage?.image).url()}
              title={item.title[state?.lang as lang] || ''}
              ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
              cardType="COUNTRY"
              cardSize={getCardSize(arr)}
            /> )}
        </Carousel>
      </PageSection>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext.slug}/${gCPT({ view: CurrentView["travel-info"], lang: state?.lang as lang })}`} 
        sanityContext={page.travelInfoIntroduction && page.travelInfoIntroduction[state?.lang as lang]} 
        id={pageID.Reiseinfo[state?.lang as lang]!} title={state?.countryName!} >
          <Card
            _className=""
            imageAlt={(page?.travelInfoImage?.image?.alt && page?.travelInfoImage?.image?.alt[state?.lang as lang]) || ''}
            imageUrl={imageURLBuilder(page?.travelInfoImage?.image).url()}
            title={''}
            bodyText={
              page?.travelInfoCountryInfo && page?.travelInfoCountryInfo[state?.lang as lang] && <PortableText content={page?.travelInfoCountryInfo[state?.lang as lang]} 
                className="" 
                template={{
                  h2: (props: any) => <Text type="H2" className="">{props.children}</Text>,
                  normal: (props: any) => <Text type="P" ptype="BASE" className="leading-7">{props.children}</Text>,
                  li: (props: any) => <li className="list-disc">{props.children}</li>,
                }} />
            }
            ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
            cardType="COUNTRY"
            cardSize="JUMBO"
          />
      </PageSection>
    </Layout>
  )
}

export const query = graphql`
  query countryIndexPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      countryIntroduction: _rawCountryIntroduction(resolveReferences: {maxDepth: 1})
      heroImage: _rawCountryImage(resolveReferences: {maxDepth: 2})
      highlightsIntroduction: _rawHighlightsIntroduction(resolveReferences: {maxDepth: 1})
      countryHighlights: _rawCountryHighlights(resolveReferences: {maxDepth: 4})
      regionIntroduction: _rawRegionsIntroduction(resolveReferences: {maxDepth: 1})
      region: _rawRegions(resolveReferences: {maxDepth: 5})
      tripIntroduction: _rawTripsIntroduction(resolveReferences: {maxDepth: 10})
      trips: _rawTrips(resolveReferences: {maxDepth: 5})
      previousTripIntroduction: _rawPreviousTripsIntroduction
      previousTrips :_rawPreviousTrips(resolveReferences: {maxDepth: 5})
      accomodationIntroduction: _rawAccommodationIntroduction
      accommodation: _rawAccommodation(resolveReferences: {maxDepth: 5})
      travelInfoIntroduction: _rawTravelInfoIntroduction
      travelInfoImage: _rawTravelInfoImage(resolveReferences: {maxDepth: 2})
      travelInfoCountryInfo: _rawTravelInfoCountryInfo
      metadata: _rawCountryLandingMetadata
    }
  }
`

export default IndexPage
