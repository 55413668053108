import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Wrapper from '../../template/ContainerMax';
import { lang } from '../../../entities/countryIndexQueries';
import PortableText from '../../sanityBlockContents/PortableText';
import { SiteContext } from '../../../Context/site'

import translations  from '../../../entities/translations';
import { useEffect } from 'react';

interface Prop {
  sanityContext: any,
  id: string,
  readMoreLink: string;
}

const HomeIntro: React.FC<Prop> = ({ sanityContext, id, readMoreLink }) => {
  const { state } = useContext(SiteContext);
  const [readMore, setReadMore] = useState(translations.globals.buttons.readMore[state?.lang as lang])

  useEffect(() => {
    setReadMore(translations.globals.buttons.readMore[state?.lang as lang])
  }, [state?.lang])

  return (
    <Wrapper>
      <div className="flex flex-col items-center" id={id}> 
        {sanityContext && <PortableText content={sanityContext} 
        className="flex flex-col items-center bg-white mt-2" 
        template={{
          h2: (props: any) => <Text type="H2" className="m-0 py-10">{props.children}</Text>,
          normal: (props: any) => <Text type="P" ptype="BASE"  className="m-0 px-0 lg:px-40 text-dove-gray font-normal">{props.children}</Text>,
          blockquote: (props: any) => <Text type="P" ptype="BASE" className="m-0 px-0 lg:px-64 font-light text-3xl text-granite-green py-12 leading-11 text-center">{props.children}</Text>,
        }} />}

        <div className="py-10">
          <Link to={readMoreLink || '/'}>
            <Button type="button" color="secondary" size="large">
              {readMore}
            </Button>
          </Link>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomeIntro;
